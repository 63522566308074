import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-010f3198"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner"
}
const _hoisted_3 = {
  key: 2,
  class: "buttonContainer"
}
const _hoisted_4 = {
  key: 3,
  class: "buttonContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.loading && _ctx.loadingOnConfirm ? 'popupContainerLoading' : 'popupContainer'),
        disabled: !_ctx.loadingOnConfirm || _ctx.loading
      }, [
        (_ctx.loading && _ctx.loadingOnConfirm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.icon != undefined)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 1,
              class: _normalizeClass([_ctx.popupIcon, _ctx.PopupColors[_ctx.color]]),
              preserveAspectRatio: "xMaxYMax meet"
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.noCharLimit ? 'noCharLimitContainer' : 'textContainer')
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.regularText ? 'mainText' : 'pinMainText'])
          }, _toDisplayString(_ctx.$t(_ctx.text)), 3),
          (_ctx.subText)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass([_ctx.regularText ? 'subText' : 'pinSubText'])
              }, _toDisplayString(_ctx.subText), 3))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.buttons === _ctx.PopupButtons.confirmCancel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "buttonSecondary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
              }, _toDisplayString(_ctx.$t("cancel")), 1),
              _createElementVNode("button", {
                class: _normalizeClass(['buttonPrimary', _ctx.PopupColors[_ctx.color]]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
              }, _toDisplayString(_ctx.$t("confirm")), 3)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                class: _normalizeClass(['buttonPrimary', _ctx.PopupColors[_ctx.color]]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
              }, _toDisplayString(_ctx.$t("close")), 3)
            ]))
      ], 10, _hoisted_1)
    ], undefined, true),
    _: 1
  }))
}